import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.es"

const IndexEN = ({location}) => {
  return (
    <>
      <Metatags
        title="Multilógica | Política de Privacidad"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/politica-de-privacidad"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
            <div className="text-5xl font-semibold text-mi-blue">
              Política de Privacidade
            </div>
            <div className=" h-8" />
            <div className=" text-left text-xl">
              <p className=" font-bold">Início</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                POLÍTICA DE PRIVACIDADE – Multilógica Comércio de Materiais
                Elétricos e Serviços Ltda ME
              </p>
              <p>&nbsp;</p>
              <p>
                A Multilógica Comércio de Materiais Elétricos e Serviços Ltda ME
                empresa inscrita no CNPJ/MF sob o nº 64.880.743/0001-86,
                doravante denominada Multilógica, valoriza a privacidade de seus
                usuários, clientes, fornecedores e colaboradores, por isso criou
                essa Política de Privacidade nos termos da Lei Geral de Proteção
                de Dados nº 13.709/2018.
              </p>
              <p>&nbsp;</p>
              <p>
                Quando você realiza um cadastro ou assina a newsletter da
                Multilógica você nos fornece alguns dados pessoais com o
                objetivo de viabilizar a sua compra. A Multilógica tem por
                finalidade demonstrar o compromisso com a segurança,
                privacidade, transparência e o tratamento dos dados pessoais,
                por isso, dedicamos este documento a explicar ao titular de
                dados quais dados são coletados, como são coletados, qual o
                tratamento dado, como é a forma de armazenamento, se há qualquer
                compartilhamento com terceiros, entre outras informações
                essenciais em relação aos dados pessoais. O usuário declara que
                fez a leitura completa e atenta, estando plenamente ciente da
                presente Política de Privacidade e Tratamento de Dados Pessoais,
                conferindo, expressamente, sua livre, inequívoca e informada
                concordância com os termos aqui estipulados, autorizando a
                obtenção dos dados pessoais e informações aqui mencionados, bem
                como sua utilização para as finalidades abaixo especificadas.
                Caso não esteja de acordo com estas diretivas, o usuário deve
                descontinuar o seu acesso ao site da Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">ÍNDICE 1.</p>
              <p>&nbsp;</p>
              <p>INFORMAÇÕES GERAIS E DEFINIÇÕES</p>
              <p>2. COLETA E TRATAMENTO DE DADOS</p>
              <p>3. ARMAZENAMENTO DE DADOS</p>
              <p>4. COMPARTILHAMENTO DE DADOS</p>
              <p>5. HIPÓTESES LEGAIS PARA DIVULGAÇÃO DOS DADOS</p>
              <p>6. RETENÇÃO DE DADOS</p>
              <p>7. DIREITOS DO TITULAR</p>
              <p>8. COOKIES</p>
              <p>9. REVISÕES DA POLÍTICA</p>
              <p>10. CONTATO</p>
              <p>11. FORO DE ELEIÇÃO E LEGISLAÇÃO</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">1. INFORMAÇÕES</p>
              <p>&nbsp;</p>
              <p>
                GERAIS E DEFINIÇÕES Usuário: todas as pessoas físicas que
                utilizarão ou visitarão o site, maiores de 18 (dezoito) anos ou
                emancipadas e totalmente capazes de praticar os atos da vida
                civil ou os absolutamente ou relativamente incapazes devidamente
                representados ou assistidos.
              </p>
              <p>&nbsp;</p>
              <p>
                Dados Pessoais: significam quaisquer informações fornecidas e/ou
                coletadas pela Multilógica, por qualquer meio, ainda que
                públicos, que: (I) identifiquem, ou que, quando usadas em
                combinação com outras informações tratadas pela Multilógica
                identifiquem um indivíduo; ou (II) por meio das quais a
                identificação ou informações de contato de uma pessoa física
                possam ser derivadas. Os Dados Pessoais podem estar em qualquer
                mídia ou formato, inclusive registros eletrônicos ou
                computadorizados, bem como em arquivos baseados em papel. Os
                Dados Pessoais, no entanto, não incluem telefone comercial,
                número de celular comercial, endereço comercial, e-mail
                comercial.
              </p>
              <p>&nbsp;</p>
              <p>
                Finalidade: o objetivo, o propósito que a Multilógica deseja
                alcançar a partir de cada ato de tratamento das informações
                pessoais.
              </p>
              <p>&nbsp;</p>
              <p>
                Necessidade: justificativa pelo qual é estritamente necessário
                coletar dados pessoais, para atingir a finalidade, evitando-se a
                coleta excessiva.
              </p>
              <p>&nbsp;</p>
              <p>
                Bases legais: fundamentação legal que torna legítimo o
                tratamento de dados pessoais para uma determinada finalidade
                prévia por parte da Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Consentimento: autorização expressa e inequívoca dada pelo
                Usuário titular do dado pessoal para que a Multilógica trate
                seus dados pessoais para uma finalidade previamente descrita, na
                qual a base legal necessária para o ato demande a autorização
                expressa do titular.
              </p>
              <p>&nbsp;</p>
              <p>
                Ao acessar e/ou utilizar o site da Multilógica, o Usuário
                declara ter no mínimo 18 (dezoito) anos e ter capacidade plena e
                expressa para a aceitação dos termos e condições desta Política
                de Privacidade e do Termo de Consentimento para todos os fins de
                direito.
              </p>
              <p>&nbsp;</p>
              <p>
                Caso o Usuário não se enquadre na descrição acima e/ou não
                concorde, ainda que em parte, com os termos e condições contidos
                nesta Política de Privacidade, não deverá acessar e/ou utilizar
                quaisquer serviços oferecidos pela Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                2. COLETA DE DADOS E TRATAMENTO DE DADOS
              </p>
              <p>&nbsp;</p>
              <p>
                O Usuário está ciente de que fornece informação de forma
                consciente e voluntária por meio de formulários de cadastro, ou
                por meio do site operado pela Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Quando o Usuário realiza o cadastro e/ou preenche formulários
                oferecidos pela Multilógica, inclusive no site por ela operado,
                determinados Dados Pessoais solicitados serão mantidos em sigilo
                e serão utilizadas apenas para os propósitos abaixo definidos:
              </p>
              <p>&nbsp;</p>
              <p>
                Dados coletados: Nome Pessoal, Razão Social, e-mail, telefone e
                nome da empresa em que trabalha (CLIENTES e USUÁRIOS WEBSITE)
                Finalidade de uso/ tratamento de dados: Para poder acessar
                conteúdos informativos sobre tributação, eventos, promoções e
                notícias relevantes à Multilógica precisará que o Usuário
                forneça seu Nome, Email, telefone e em qual Empresa trabalha. A
                Multilógica vai enviar e-mail marketing para os usuários que
                fornecerem essas informações. Os Dados Pessoais coletados não
                serão utilizados para envio de qualquer tipo de SPAM ou outro
                fim que não o aqui exposto. Base Legal: Consentimento
                Consentimento: Estou de acordo em fornecer meu Nome, Email e em
                qual Empresa eu trabalho para acessar o material disponibilizado
                no site, além de receber Newsletter da Multilógica e demais
                e-mails marketing. Estou ciente de que meus dados fornecidos
                serão utilizados internamente para fins promocionais pela
                Multilógica e concordo com o recebimento de emails da
                Multilógica.
              </p>
              <p>&nbsp;</p>
              <p>
                Dados coletados: Nome/Razão Social, telefone, CEP e e-mail (SAC)
                Finalidade de uso/ tratamento de dados: Caso o titular de dados
                queira entrar em contato com a empresa por qualquer meio
                (telefone, presencial ou via website) para solicitar
                informações. Base Legal: Consentimento Consentimento: Estou de
                acordo em fornecer meu nome e e-mail para Multilógica retornar
                meu contato/ pedido de informações.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">3. ARMAZENAMENTO DE DADOS</p>
              <p>&nbsp;</p>
              <p>
                A Multilógica armazenará as informações coletadas em servidores
                próprios ou por ela contratados em nuvem pelos fornecedores de
                serviços contratados por ela.
              </p>
              <p>&nbsp;</p>
              <p>
                A Multilógica utiliza os meios razoáveis de mercado e legalmente
                requeridos para preservar a privacidade dos dados coletados.
                Desta forma, adota, por exemplo, as seguintes precauções:
              </p>
              <p>&nbsp;</p>
              <p>
                Quando necessário a Multilógica utiliza os métodos padrão e de
                mercado para criptografar e anonimizar os dados coletados; A
                Multilógica possui proteção contra acesso não autorizado aos
                seus sistemas; A Multilógica somente autoriza o acesso de
                pessoas previamente estabelecidas ao local onde são armazenadas
                as informações coletadas; Aqueles que entram em contato com as
                informações se comprometem a manter sigilo absoluto. A quebra do
                sigilo acarretará responsabilidade civil e o responsável será
                responsabilizado nos moldes da legislação brasileira; A
                Multilógica adota os melhores esforços, no sentido de preservar
                a privacidade dos dados dos Usuários. Entretanto, nenhum site é
                totalmente seguro e a Multilógica não pode garantir
                integralmente que todas as informações que trafegam nas Páginas
                não sejam alvo de acessos não autorizados perpetrados por meio
                de métodos desenvolvidos para obter informações de forma
                indevida.
              </p>
              <p>&nbsp;</p>
              <p>
                Por esse motivo, nós incentivamos os Usuários a tomarem as
                medidas apropriadas para se protegerem, como, por exemplo,
                mantendo confidenciais todos os nomes de usuário e senhas
                criados.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">4. COMPARTILHAMENTO DE DADOS</p>
              <p>&nbsp;</p>
              <p>
                A Multilógica poderá divulgar os dados pessoais coletados de
                terceiros nas seguintes situações e nos limites autorizados pela
                lei: Para propósitos administrativos como: pesquisa,
                planejamento, desenvolvimento de serviços, segurança, emissão de
                nota fiscal, banco, plataforma de pagamento e gerenciamento de
                risco e outros parceiros comerciais ou administrativos; Com
                parceiros comerciais com o objetivo de viabilizar a entrega dos
                produtos aquiridos com a Multilógica; Quando necessário para
                cumprir uma obrigação legal, determinação de autoridade
                competente ou decisão judicial.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">
                5. HIPÓTESES LEGAIS PARA DIVULGAÇÃO DOS DADOS
              </p>
              <p>&nbsp;</p>
              <p>
                Em alguns casos a Multilógica poderá divulgar os Dados Pessoais
                coletados com o objetivo de cumprir a legislação aplicável ou
                por meio de ordem ou intimação judicial ou administrativa.
              </p>
              <p>&nbsp;</p>
              <p>A divulgação legal poderá ser feita para:</p>
              <p>&nbsp;</p>
              <p>
                Cumprir com legislação; Investigar, impedir ou tomar medidas
                relacionadas à cooperação com órgãos públicos ou para proteger a
                segurança nacional; Execução de contratos; Investigação e defesa
                de alegações de terceiros; Proteção da segurança ou integridade
                dos serviços;
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">6. RETENÇÃO DE DADOS</p>
              <p>&nbsp;</p>
              <p>
                A Multilógica irá reter informações dos Titulares de dados
                somente pelo período da finalidade para as quais aquelas
                informações foram tratadas, incluindo a segurança do
                processamento, com obrigações jurídicas e regulamentares (por
                exemplo, auditoria, contabilidade e termos de retenção
                estatutária), litígios quanto ao processamento, e para o
                estabelecimento, exercício ou defesa de ações judiciais.
              </p>
              <p>&nbsp;</p>
              <p>
                O titular de dados poderá solicitar a exclusão dos dados por
                meio de solicitação direta com a empresa pelo endereço de
                e-mail: contato@multilogica.ind.br ou telefone (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">7. DIREITOS DO TITULAR</p>
              <p>&nbsp;</p>
              <p>
                Em cumprimento à regulamentação aplicável, no que diz respeito
                ao tratamento de dados pessoais, a Multilógica respeita e
                garante ao Usuário, a possibilidade de apresentação de
                solicitações baseadas nos seguintes direitos:
              </p>
              <p>&nbsp;</p>
              <p>
                a confirmação da existência de tratamento; o acesso aos dados; a
                correção de dados incompletos, inexatos ou desatualizados; a
                anonimização, bloqueio ou eliminação de dados desnecessários,
                excessivos ou tratados em desconformidade; a eliminação dos
                dados tratados com consentimento do Usuário; a obtenção de
                informações sobre as entidades públicas ou privadas com as quais
                a Multilógica compartilhou seus dados; a informação sobre a
                possibilidade de não fornecer o seu consentimento, bem como de
                ser informado sobre as consequências, em caso de negativa; a
                revogação do consentimento. A Multilógica se coloca a disposição
                para cumprimento dos direitos dos titulares de dados por meio de
                solicitação do titular a ser enviada pelo endereço de e-mail
                contato@multilogica.ind.br ou telefone (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>
                A Multilógica empreenderá todos os esforços para atender tais
                pedidos no menor espaço de tempo possível.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">8. REVISÕES DA POLÍTICA</p>
              <p>&nbsp;</p>
              <p>
                Caso a Multilógica modifique a presente política, tais
                alterações serão publicadas de forma visível no site da
                Multilógica, bem como sempre que possível será informado para o
                titular de dados.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">9. COOKIES</p>
              <p>&nbsp;</p>
              <p>
                Cookies são arquivos ou informações que podem ser armazenadas em
                seus dispositivos, quando você visita as Páginas da Multilógica.
                Geralmente, um cookie contém o nome do site que o originou, seu
                tempo de vida e um valor, que é gerado aleatoriamente.
              </p>
              <p>&nbsp;</p>
              <p>
                A Multilógica utiliza cookies para facilitar o uso e melhor
                adaptar as Páginas aos seus interesses e necessidades, bem como
                para compilarmos informações sobre a utilização de nosso site,
                auxiliando a melhorar suas estruturas e seus conteúdos. Os
                cookies também podem ser utilizados para acelerar suas
                atividades e experiências futuras nas Páginas.
              </p>
              <p>&nbsp;</p>
              <p>
                Tipos de cookies: Necessários O que eles fazem? Esses cookies
                nos ajudam a entender como os visitantes interagem com as
                Páginas da Multilógica, fornecendo informações sobre as áreas
                visitadas, o tempo de visita ao site e quaisquer problemas
                encontrados, como mensagens de erro.
              </p>
              <p>&nbsp;</p>
              <p>
                Tipos de cookies: Funcionais O que eles fazem? Esses cookies
                permitem que as Páginas da Multilógica se lembrem de suas
                escolhas, para proporcionar uma experiência mais personalizada.
              </p>
              <p>&nbsp;</p>
              <p>
                Tipos de cookies: Marketing O que eles fazem? Esses cookies são
                utilizados para fornecer mais conteúdo relevante e do interesse
                dos Usuários. Podem ser utilizados para apresentar publicidade
                mais direcionada ao usuário. Também, permitem a medição da
                eficácia de uma campanha publicitária da Multilógica. Ainda,
                esses cookies podem ser utilizados para indicar às Páginas da
                Multilógica os sites que o Usuário visitou.
              </p>
              <p>&nbsp;</p>
              <p>
                Após o Usuário consentir para a utilização de cookies, quando do
                uso das Páginas da Multilógica, esta armazenará um cookie em seu
                dispositivo para lembrar disso na próxima sessão.
              </p>
              <p>&nbsp;</p>
              <p>
                A qualquer momento, o Usuário poderá revogar seu consentimento
                quanto aos cookies, pelo que deverá apagar os cookies das
                Páginas da Multilógica utilizando as configurações de seu
                navegador de preferência. Para mais informações sobre como
                proceder em relação à gestão dos cookies nos navegadores:
              </p>
              <p>&nbsp;</p>
              <p>Internet Explorer:</p>
              <p>
                <a href="https://support.microsoft.com/pt-br/help/17442/windows-internetexplorer-delete-manage-cookies" rel="noopener noreferrer" target="_blank">
                  https://support.microsoft.com/pt-br/help/17442/windows-internetexplorer-delete-manage-cookies
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Mozilla Firefox:</p>
              <p>
                <a href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-queos-sites-usam" rel="noopener noreferrer" target="_blank">
                  https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-queos-sites-usam
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Google Chrome:</p>
              <p>
                <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR" rel="noopener noreferrer" target="_blank">
                  https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pt-BR
                </a>
              </p>
              <p>&nbsp;</p>
              <p>Safari:</p>
              <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" rel="noopener noreferrer" target="_blank">
                <p>
                  https://support.apple.com/pt-br/guide/safari/sfri11471/mac
                </p>
              </a>
              <p>&nbsp;</p>
              <p>
                Por fim, lembramos que, caso o Usuário não aceite alguns cookies
                das Páginas da Multilógica, certos serviços poderão não
                funcionar de maneira ideal.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">10. CONTATO</p>
              <p>&nbsp;</p>
              <p>
                A Multilógica se coloca à disposição para solução de quaisquer
                dúvidas ou solicitações dos titulares de dados pelo endereço de
                e-mail: contato@multilogica.ind.br por meio de contato
                telefônico pelo número (11) 4319-1041.
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p className=" font-bold">11. FORO DE ELEIÇÃO E LEGISLAÇÃO</p>
              <p>&nbsp;</p>
              <p>
                Esta Política será regida, interpretada e executada de acordo
                com as Leis da República Federativa do Brasil, especialmente a
                Lei nº 13.709/2018, independentemente das Leis de outros estados
                ou Países, sendo competente o foro de Santo André, São Paulo
                para dirimir qualquer dúvida decorrente deste documento.
              </p>
              <p>&nbsp;</p>
              <p>Última atualização: 10 de novembro de 2021.</p>
              <p>&nbsp;</p>
              <p>
                Multilógica Comércio de Materiais Elétricos e Serviços Ltda. -
                ME
              </p>
            </div>
          </div>
        </div>
        <div className="h-10" />
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
